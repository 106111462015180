import { FunctionComponent } from "react";
import { Location, useLocation, NavLink, To, useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { ITheme, getTheme } from "../assets/css/variables";

import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetState } from "../store/scriptSlice";

import { ProgressBar } from "./ProgressBar";

const sparrowscriptsLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-sparrowscripts-full.png`;
const backIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/back-arrow.svg`;

type HeaderProps = {

}

const progressSteps = [
  '/patient',
  '/prescription',
  '/delivery',
  '/checkout'
]

export const Header: FunctionComponent<HeaderProps> = ({ }) => {
  const domLocation: Location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const config = useAppSelector((state) => state.script.config);
  const theme: ITheme = getTheme(config.theme_key);
  
  // methods
  const navigateHome = (): void => {
    dispatch(resetState());
    navigate('/');
  }

  // computed
  const showBackButton = (): boolean => {
    return [
      '/',
      '/confirmation'
    ].includes(domLocation.pathname) === false;
  }

  const showProgress = (): boolean => {
    return domLocation.pathname !== '/confirmation';
  }

  const currentStep = (): number => {
    const index = progressSteps.findIndex((step) => step === domLocation.pathname);
    if (index !== -1) {
      return index + 1;
    } else if (domLocation.pathname === '/help') {
      return 2;
    } else {
      return 0;
    }
  }

  return (
    <>
      <StyledHeader className={`Header Header_${process.env.REACT_APP_TARGET}`}>
        {config.partner_id !== null ?
          <>
            {process.env.REACT_APP_TARGET === 'app' &&
              <>
                <img className="Header_logo" src={theme.images.logoHeader} alt="" />

                {showBackButton() &&
                  <NavLink to={-1 as To}>
                    <img src={backIcon} alt="" />
                  </NavLink>
                }
              </>
            }

            {process.env.REACT_APP_TARGET !== 'app' &&
              <div className="Header_inner">
                <img className="Header_logo pointer" src={theme.images.logoHeader} alt="" onClick={navigateHome} />
                <p className="Header_store bold">{config.location_name}</p>
              </div>
            }
          </>
        :
          <div className="Header_inner">
            <img className="Header_sparrowscriptsLogo" src={sparrowscriptsLogo} alt="SparrowScripts" title="SparrowScripts" />
          </div>
        }
      </StyledHeader>
      

      {showProgress() &&
        <div>
          <ProgressBar current={currentStep()} length={progressSteps.length} />
        </div>
      }
    </>
  );
}

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  height: 104px;
  min-height: 104px;
  background: ${props => props.theme.components.header.background};
  padding: 35px 30px 0 25px;
  border-radius: 0 0 2px 2px;
  z-index: 1;
  box-shadow: ${props => props.theme.components.header.shadow};

  display: flex;
  align-items: center;
  justify-content: center;

  .Header_inner {
    width: 100%;
    max-width: 640px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 24px;

    @media screen and (max-width: 650px) {
      padding: 0;
      gap: 20px;
    }
  }

  .Header_logo {
    width: 100%;
    height: 100%;
    max-width: 172px;
    max-height: 60px;
    object-fit: contain;
    object-position: left;

    @media screen and (max-width: 650px) {
      max-width: 150px;
      max-height: 50px;
    }
  }

  a {
    display: flex;
    align-items: center;

    position: absolute;
    bottom: 8px;
    left: 13px;
    color: black;
    text-decoration: none;

    font-size: 1rem; // 16px

    img {
      padding: 10px;
    }

    
    /* animation */
    cursor: pointer;
    transition: transform ${props => props.theme.transitions.duration.fast};
    
    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  &.Header_desktop {
    padding-top: 0;
    justify-content: space-between;

    .Header_store {
      text-align: right;
      color: ${props => props.theme.components.header.text};
    }
  }

  .Header_sparrowscriptsLogo {
    width: 230px;
    height: auto;
    margin: auto;
  }
`;
