import { ChangeEvent, FormEvent, FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ITheme, ThemeKey, getTheme, getThemeKey } from "../assets/css/variables";
import YouTube from 'react-youtube';

import { useApi } from "../context/ApiProvider";
import { FilteredLocation, PublicGetLocationsByPartnerIDRequest } from "sparrowhub-client-axios";

import { setConfig, setScript } from '../store/scriptSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { Button, ButtonType } from "../components/Button";
import { InputField } from "../components/InputField";

const homepageHeaderSmiths = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-smiths.png`;
const homepageHeaderPriceline = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-priceline.png`;
const homepageHeaderChemistworks = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-chemistworks.png`;
const homepageHeaderCatAndClaudias = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-homepage-catandclaudias.png`;
const homepageBackground = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/homepage-bg.svg`;
const instantscriptsLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-instantscripts.png`;

const doordashLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-doordash.png`;
const auspostExpressLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost-express.png`;
const auspostLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-auspost.png`;
const aramexLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-aramex.png`;

const escriptHelp = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/escript-help-token.gif`;

type HomePageProps = {
}

export const HomePage: FunctionComponent<HomePageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const api = useApi();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);

  // state
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPage, setShowPage] = useState(false);

  // computed
  const hideBackground = (): void => {
    const elements = [
      document.body.getElementsByTagName('main')[0].parentElement,
      document.body.getElementsByTagName('footer')[0]
    ]
    elements.forEach((element: any) => {
      if (element) element.style.background = 'none';
    });
  }

  const videoOptions = (): any => {
    if (document.documentElement.clientWidth > 650) {
      return {
        width: '315',
        height: '177',
        playerVars: {
          autoplay: 1,
        }
      }
    } else {
      return {
        width: '275',
        height: '155',
        playerVars: {
          autoplay: 1,
        }
      }
    }
  }

  // methods
  const next = (formEvent: FormEvent): void => {
    formEvent.preventDefault();

    if (scripts[0].token !== '') {
      // manually submit GA event
      const formButtons = formEvent.currentTarget.getElementsByTagName('button');
      gtag('event', 'form_submit', {
        'form_id': formEvent.currentTarget.id,
        'form_name': (formEvent.currentTarget as any).name,
        'form_submit_text': formButtons[formButtons.length - 1].innerHTML
      });

      // handle next
      setShowPage(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      setTimeout(() => {
        navigate('/patient');
      }, 300);
    }
  }

  const openExternal = (url: string): void => {
    if (window.cordova) {
      window.cordova.InAppBrowser.open(url, '_system');
    } else {
      window.open(encodeURI(url), '_blank');
    }
  }

  const handleGetPartnerLocations = async (partner_id: number, token: string): Promise<any> => {
    const requestBody: PublicGetLocationsByPartnerIDRequest = {
      security_token: token
    }

    api.publicGetLocationsByPartnerID(partner_id, requestBody).then((response) => {
      if (response.status === 200) {
        const data = JSON.parse(response.data.data as unknown as string);
        const location = data.find((location: FilteredLocation) => location.is_default);

        if (location !== undefined && location !== null) {
          const address = location.address;
          const addressString = `${address.street ? JSON.parse(address.street).join(', ') : null}, ${address.city} ${address.region_code} ${address.postcode}`;
          dispatch(setConfig({
            location_code: location.code,
            location_name: address.name,
            location_address: addressString,
            location_phone: location.support_phone,
            location_email: location.support_email
          }));
        } else {
          // handle Smith's Pharmacy case
          if (partner_id === 99) {
            dispatch(setConfig({
              location_code: 'cw-broadway', // set to CW Broadway to avoid error in demo
              location_name: 'Smith\'s Pharmacy Broadway',
              location_address: 'Shop G20A/1 Bay St, Ultimo NSW 2007',
              location_phone: '94123456',
              location_email: 'dev@rivalsoftware.com.au'
            }));
          }
        }
      } else {
        navigate('/partners');
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const onPlayerReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  // initial page setup
  useEffect(() => {
    (async () => {
      // read partner param if not set already
      if (config.partner_id === null) {
        const partnerParam = searchParams.get('partner');
        const widgetPartner = (window as any).rival_scripts_partnerid;

        if (partnerParam === null && widgetPartner === undefined) {
          navigate('/partners');
        } else {
          let decodedPartnerId: number | null = null;
          try {
            decodedPartnerId = parseInt(atob(decodeURIComponent(partnerParam || widgetPartner || '')));
          } catch (error) {
            navigate('/partners');
          }

          const themeKey: ThemeKey = getThemeKey(decodedPartnerId!);

          // configure recaptcha key
          let recaptchaKey: string | undefined = undefined;
          if (process.env.REACT_APP_TARGET !== 'widget') {
            recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
          } else {
            if (window.rival_scripts_mode === 'live') {
              recaptchaKey = '6LeymTcpAAAAAGnDJ4l-pRZsI3Kiv8GB73WOmm0E';
            } else {
              recaptchaKey = '6LfvmjcpAAAAAN1fn5NdR1t2xpGM4bpZbDxjP09s';
            }
          }

          // set config in store
          dispatch(setConfig({
            partner_id: decodedPartnerId,
            theme_key: themeKey,
            has_landed: true,
            recaptcha_key: recaptchaKey,
          }));

          setShowPage(true);

          // generate reCAPTCHA token
          await grecaptcha.enterprise.ready(async () => {
            const token = await grecaptcha.enterprise.execute(recaptchaKey, { action: 'get_locations' });
            handleGetPartnerLocations(decodedPartnerId!, token);
          });

          if (process.env.REACT_APP_TARGET === 'widget' || themeKey === ThemeKey.Priceline) hideBackground();

          // dynamic document head settings
          const theme: ITheme = getTheme(themeKey);

          // font sizes
          const documentElement = document.documentElement;
          if (process.env.REACT_APP_TARGET !== 'app') {
            if (documentElement.clientWidth > 600) {
              documentElement.style.fontSize = theme.textSizes.desktop;
            } else {
              documentElement.style.fontSize = theme.textSizes.mobile;
            }
          } else {
            documentElement.style.fontSize = theme.textSizes[process.env.REACT_APP_TARGET! as keyof typeof theme.textSizes];
          }

          if (process.env.REACT_APP_TARGET !== 'widget') {
            // page title
            document.title = theme.data.name;
            // favicon
            let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = theme.images.favicon;
          } else {
            // 2024-05-31 Update: Requested that the widget visitors remain on the home page
            // skip to prescription page
            // navigate('/prescription');
          }
        }
      } else {
        setShowPage(true);
      }

      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 10);
    })();
  }, []);

  return (
    <StyledHomePage className={`pageTransition ${!showPage && 'hidden'}`}>
      <div className="HomePage_content">
        <div className="HomePage_cta fullWidth_parent">
          <div className="fullWidth_child">
            {process.env.REACT_APP_TARGET === 'desktop' &&
              <>
                {config.theme_key === ThemeKey.Priceline &&
                  <img className="HomePage_headerImage" src={homepageHeaderPriceline} alt="Order e-scripts in four steps" />
                }
                {config.theme_key === ThemeKey.SmithsPharmacy &&
                  <img className="HomePage_headerImage banner" src={homepageHeaderSmiths} alt="E-scripts made easy" />
                }
                {config.theme_key === ThemeKey.Chemistworks &&
                  <img className="HomePage_headerImage banner" src={homepageHeaderChemistworks} alt="E-scripts made easy" />
                }
                {config.theme_key === ThemeKey.CatAndClaudias &&
                  <img className="HomePage_headerImage banner" src={homepageHeaderCatAndClaudias} alt="E-scripts made easy" />
                }
                {([ThemeKey.Priceline, ThemeKey.SmithsPharmacy, ThemeKey.Chemistworks, ThemeKey.CatAndClaudias] as any).includes(config.theme_key) === false &&
                  <h1 className="HomePage_header">Order e-scripts in four steps!</h1>
                }
              </>
            }

            {process.env.REACT_APP_TARGET === 'app' &&
              <>
                {config.theme_key === ThemeKey.Priceline ?
                  <img className="HomePage_headerImage" src={homepageHeaderPriceline} alt="Order e-scripts in four steps" />
                  :
                  <h1 className="HomePage_header">Order e-scripts in four steps!</h1>
                }
              </>
            }

            <p className="cta_text bold">Add your e-script token to get started</p>
            <form name="Add your e-script token to get started" id="form_home-page_escript-token" onSubmit={next}>
              <InputField type="text" value={scripts[0].token} onChange={(e: ChangeEvent) => dispatch(setScript({ index: 0, token: (e.target as HTMLInputElement).value }))} large uppercase />
              <Button text="Find Prescription" type={ButtonType.Primary} />
            </form>
          </div>
        </div>

        {process.env.REACT_APP_TARGET === 'widget' &&
          <div className="HomePage_help fullWidth_parent">
            <div className="HomePage_help fullWidth_child">
              <p className="bold">Where can I find the medication name?</p>
              <img src={escriptHelp} alt="" />

              <div className="getHelp">
                <p className="bold italic">Unsure about e-scripts?</p>
                <a href="/how-do-electronic-prescriptions-work" target="_blank" rel="noreferrer">
                  <button className="bold button">Get More Information</button>
                </a>
              </div>
            </div>
          </div>
        }

        {process.env.REACT_APP_TARGET !== 'widget' &&
          <div className="HomePage_info fullWidth_parent">
            <div className="HomePage_infoInner fullWidth_child">
              <h3>Fill your e-script in four easy steps</h3>

              <h4>1. Enter your token</h4>
              <p>Your token is a 17 digit code that can be found on your e-script.</p>
              <div className="video">
                <YouTube videoId="s8XIT_d2lnk" opts={videoOptions()} onReady={onPlayerReady} />
              </div>

              <h4>2. Enter your details</h4>
              <p>We will need some information from you to verify your prescription and concessions.</p>

              <h4>3. Delivery or pickup</h4>
              <p>Choose to collect your medication in store or have it delivered to your door.</p>
              <div className="couriers">
                <img id="doordash" src={doordashLogo} alt="DoorDash" />
                <img id="auspostExpress" src={auspostExpressLogo} alt="Australia Post Express" />
                <img id="auspost" src={auspostLogo} alt="Australia Post" />
                <img id="aramex" src={aramexLogo} alt="Aramex" />
              </div>

              <h4>4. Finalise payment</h4>
              <p>Checkout securely with a range of payment methods.</p>
            </div>
          </div>
        }

        <div className="HomePage_telehealth fullWidth_parent">
          <div className="fullWidth_child">
            <p className="bold">Need a prescription from a doctor?</p>
            <a href="https://www.instantscripts.com.au/cat/DocCons" target="_blank" rel="noreferrer">
              <Button text="Book a TeleHealth Appointment" type={ButtonType.Secondary} />
            </a>
            {/* <Button text="Book a TeleHealth Appointment" type={ButtonType.Secondary} onClick={() => openExternal('https://www.instantscripts.com.au/cat/DocCons')} /> */}
            <img className="HomePage_instantScripts" src={instantscriptsLogo} alt="Delivered by InstantScripts" />
          </div>
        </div>
      </div>
    </StyledHomePage>
  );
}

const StyledHomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .HomePage_content {
    margin: auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .HomePage_cta {
      background: ${props => props.theme.colours.white};

      .cta_text {
        font-size: 1.22rem !important; // 22px
        text-align: center;
        color: ${props => props.theme.colours.primary};
        margin: 75px 0 5px 0;
      }
      
      .HomePage_headerImage {
        width: 100%;
        max-width: 400px;
        margin: 70px auto 20px auto;

        &.banner {
          max-width: unset;
          border-radius: 12px;
        }
      }
      
      .HomePage_header {
        margin: 70px 0 30px 0;
      }
      
      .HomePage_subheader {
        margin: -10px 0 50px 0;
        font-size: 1.25rem; // 20px
      }

      .Button_primary {
        margin-top: 25px;
        margin-bottom: 110px;
      }
      
      .Button_secondary {
        label {
          font-size: 0.875rem; // 14px
        }
      }

      @media screen and (max-width: 650px) {
        .cta_text {
          /* text-align: left;
          font-size: 1.125rem !important; // 18px */
          padding: 0 25px;
        }
      }
    }

    .HomePage_telehealth {
      background: ${props => props.theme.colours.white};
      margin-bottom: -40px;

      .fullWidth_child {
        padding: 90px 0;
        display: flex;
        flex-direction: column;

        p {
          font-size: 1.22rem !important; // 22px
          text-align: center;
          margin: 0 0 45px 0;
        }

        .HomePage_instantScripts {
          width: 133px;
          height: auto;
          margin: 10px auto 0 auto;
        }
      }

      @media screen and (max-width: 650px) {
        .fullWidth_child {
          padding: 50px 0;

          p {
            margin-bottom: 25px;
          }
        }
      }
    }


    .HomePage_info {
      background: ${props => props.theme.colours.background};
      padding: 130px 24px;

      .HomePage_infoInner {
        padding: 65px 90px;
        text-align: center;
        background: ${props => props.theme.colours.white};
        box-shadow: 0 0 5px rgba(180, 180, 180, 0.25);
        border-radius: 6px;

        h3 {
          font-size: 1.22rem; // 22px
        }
        
        h4 {
          font-size: 1rem; // 18px
          padding: 5px 0;
          border-radius: 33px;
          color: ${props => props.theme.colours.white};
          background: ${props => props.theme.colours.secondary};
          margin-top: 70px;
        }

        p {
          font-size: 0.88rem; // 16px
        }

        .video {
          width: 315px;
          max-width: 100%;
          height: 177px;
          overflow: hidden;
          border-radius: 10px;
          margin: 30px auto 0 auto;
        }

        .couriers {
          margin-top: 30px;

          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 15px 25px;
          gap: 15px 25px;

          img {
            height: auto;

            &#doordash {
              width: 157px;
            }
            &#auspostExpress {
              width: 139px;
            }
            &#auspost {
              width: 136px;
            }
            &#aramex {
              width: 121px;
              margin-top: -7px;
            }

            &:nth-child(2n - 1) {
              margin-left: auto;
            }
          }
        }
      }
      
      @media screen and (max-width: 650px) {
        padding: 70px 24px;

        .HomePage_infoInner {
          padding: 45px 24px;

          h4 {
            margin-top: 50px;
          }

          .video {
            width: 275px;
            height: 155px;
          }

          #doordash {
            width: 118px !important;
          }
          #auspostExpress {
            width: 105px !important;
          }
          #auspost {
            width: 118px !important;
          }
          #aramex {
            width: 90px !important;
            margin-top: -12px;
          }
        }
      }
    }
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-image: ${props => props.theme.id === ThemeKey.Priceline && `url(${homepageBackground})`};
    background-position: bottom center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .fullWidth_parent {
    /* width: 100vw !important;
    transform: translateX(calc((100vw - 640px) * -0.5)); */
    width: 10000px !important;
    transform: translateX(calc((10000px - 592px) * -0.5));
    display: flex;
    flex-direction: column;
    align-items: center;

    .fullWidth_child {
      width: 100%;
      max-width: 592px;
    }

    @media screen and (max-width: 650px) {
      width: calc(100% + 24px + 24px) !important;
      transform: translateX(-24px);
      padding: 0 24px;
    }
  }

  .HomePage_help {
    p {
      font-size: 1.22rem !important; // 22px
      text-align: center;
      margin: 0 0 45px 0;

      @media screen and (max-width: 650px) {
        margin-bottom: 25px;
      }
    }

    img {
      max-width: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 5px;
    }

    .getHelp {
      padding: 18px;
      background: ${props => props.theme.colours.background};
      text-align: center;
      margin: 20px 0;
      border-radius: 6px;

      p {
        font-size: 0.85rem !important;
        margin: 0 0 12px 0;
      }

      button {
        /* width: 84px; */
        color: ${props => props.theme.colours.secondary};
        border: 1px solid ${props => props.theme.colours.secondary};
        background: none;
        border-radius: 6px;
        padding: 6px 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      }
    }
  }
`;
