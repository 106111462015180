import { FunctionComponent, useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Navigate, useNavigate } from "react-router";
import styled from "styled-components";

import { useApi } from "../context/ApiProvider";
import { NotificationTypes, EmailTypes, ProcessNotificationNewRequest, ErrorCode } from "sparrowhub-client-axios";

import { useAppSelector } from "../store/hooks";
import { ITheme, getTheme } from "../assets/css/variables";
import { formatPhoneString } from "../store/scriptSlice";

import { Button, ButtonType } from "../components/Button";
import { BackButton } from "../components/BackButton";
import { ContentBlock } from "../components/ContentBlock";
import { InputField } from "../components/InputField";
import { Alert, AlertIcon, AlertType } from "../components/Alert";
import { StoreDetails } from "../components/StoreDetails";

import { supportEmailTemplate } from '../assets/emails/scripts-support-content';

const warningIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-warning.svg`;

type HelpPageNewProps = {
  error?: boolean
}

export const HelpPageNew: FunctionComponent<HelpPageNewProps> = ({ error }) => {
  const api = useApi();
  const navigate = useNavigate();

  // store
  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  const theme: ITheme = getTheme(config.theme_key);

  // state
  const [showPage, setShowPage] = useState(false);
  const [firstname, setFirstname] = useState(scripts[scripts.length - 1].customer_firstname);
  const [lastname, setLastname] = useState(scripts[scripts.length - 1].customer_lastname);
  const [email, setEmail] = useState(scripts[scripts.length - 1].customer_email);
  const [phone, setPhone] = useState(scripts[scripts.length - 1].delivery_phone);
  const [token, setToken] = useState(scripts[scripts.length - 1].token);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  // computed
  const formIsInvalid = (): boolean => {
    const formEl = (document.getElementById('form_help-page_contact') as HTMLFormElement);
    return (
      firstname === '' ||
      lastname === '' ||
      email === '' ||
      phone === '' ||
      token === '' ||
      (formEl && !formEl.checkValidity())
    )
  }

  // computed
  const errorHeading = (): string => {
    switch (config.error) {
      case ErrorCode.NoResults:
      case ErrorCode.NotFound:
        return 'A pharmacist needs to contact you before we can send this script';
      case ErrorCode.AlreadyDispensed:
        return 'Your e-script information is unavailable';
      case ErrorCode.BadToken:
        return 'An invalid token was submitted';
      case ErrorCode.MedicationKnowledge:
        return 'This service is temporarily unavailable';
      case ErrorCode.UnexpectedError:
      default:
        return 'An error occurred while trying to process your request';
    }
  }
  
  const errorBody = (): Array<string> => {
    // handle MK errors
    switch (config.error) {
      case ErrorCode.NoResults:
        return [
          'Unfortunately, we were unable to automatically look up and price your medication.',
          `Press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
      case ErrorCode.AlreadyDispensed:
        return [
          'Unfortunately, we are unable to retrieve your e-script as the medication has already been dispensed.',
          `Press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
      case ErrorCode.NotFound:
        return [
          'Unfortunately, we are unable to retrieve your e-script. The token may have been entered incorrectly, or may be expired.',
          `Press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
      case ErrorCode.BadToken:
        return [
          'It looks like the token entered is invalid. Please check that the token matches your e-script and try again.',
          `If the issue persists, press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
      case ErrorCode.MedicationKnowledge:
        return [
          'We are working quickly to resolve this issue and apologise for the delay. Please try again in a few minutes.',
          `If the issue persists, press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
      case ErrorCode.UnexpectedError:
      default:
        return [
          `Please try again. If the issue persists, press the submit button below and a pharmacist from ${config.location_name || 'our pharmacy'} will be in touch shortly to assist.`
        ];
    }
  }


  // methods
  const submitForm = async (formEvent: FormEvent): Promise<void> => {
    // manually submit GA events
    formEvent.preventDefault();
    const formButtons = formEvent.currentTarget.getElementsByTagName('button');
    gtag('event', 'form_submit', {
      'form_id': formEvent.currentTarget.id,
      'form_name': (formEvent.currentTarget as any).name,
      'form_submit_text': formButtons[formButtons.length - 1].innerHTML
    });
    gtag('event', 'generate_lead');

    // set state
    setErrorMessage('');
    setIsLoading(true);

    // generate reCAPTCHA token
    await grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(config.recaptcha_key, { action: 'contact' });
      handleProcessNewNotification(token);
    });
  }

  const handleProcessNewNotification = (securityToken: string): void => {
    // prepare email contents
    let message = supportEmailTemplate;
    message = message.replaceAll('{token}', token);
    message = message.replaceAll('{firstname}', firstname);
    message = message.replaceAll('{lastname}', lastname);
    message = message.replaceAll('{email}', email);
    message = message.replaceAll('{mobile}', phone);
    message = message.replaceAll('{pharmacyName}', config.location_name || '');

    // prepare request body
    const requestBody: ProcessNotificationNewRequest = {
      security_token: securityToken,
      notification_type_code: NotificationTypes.Email,
      partner: {
        id: config.partner_id!
      },
      contact: {
        first_name: config.location_name || '',
        last_name: '',
        email: config.location_email!
      },
      email_type_code: EmailTypes.Support,
      subject: 'Assistance Required: Unable to Price Drug',
      message: message
    }

    api.publicProcessNewNotification(requestBody).then(async (response) => {
      setIsLoading(false);
      setFormSubmitted(true);
      const data = JSON.parse((response.data as any).data);
      console.log(data);
    })
      .catch(error => {
        console.log('error response')
        console.log(error);

        setIsLoading(false);
        setErrorMessage(error.response.data.message);
      })
  }

  useEffect(() => {
    setShowPage(true);
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
        :
        <>
          <StyledHelpPageNew className={`pageTransition ${!showPage && 'hidden'}`}>
            {process.env.REACT_APP_TARGET !== 'app' &&
              <div style={{ margin: '40px 0 10px 0', height: '24px' }}>
                <BackButton text="Back" absolute />
              </div>
            }

            <ContentBlock id="help">
              <div className="HelpPageNew_heading">
                {error ?
                  <>
                    <img src={warningIcon} alt="Error" />
                    <p className="heading bold">{errorHeading()}</p>
                    {errorBody().map((paragraph, i) => {
                      return (
                        <p key={i} className="semibold">{paragraph}</p>
                      )
                    })}
                  </>
                :
                  <>
                    <p className="heading bold">We're here to help</p>
                    <p className="semibold">Press the submit button below and a pharmacist from {config.location_name} will be in touch shortly.</p>
                  </>
                }

              </div>

              <div className="HelpPageNew_form">
                <div style={{ marginTop: '0px', minHeight: '370px' }}>
                  {!formSubmitted ?
                    <>
                      <form name="Or, enter your details below and a pharmacist will call you back" id="form_help-page_contact" onSubmit={submitForm}>
                        <div className="inputColumns">
                          <InputField type="text" label="First name" name="fname" autocomplete="given-name" value={firstname} onChange={(e: ChangeEvent) => setFirstname((e.target as HTMLInputElement).value)} required />
                          <InputField type="text" label="Last name" name="lname" autocomplete="family-name" value={lastname} onChange={(e: ChangeEvent) => setLastname((e.target as HTMLInputElement).value)} required />
                          <InputField type="email" label="Email" name="email" autocomplete="email" value={email} onChange={(e: ChangeEvent) => setEmail((e.target as HTMLInputElement).value)} required />
                          <InputField type="tel" label="Mobile number" name="mobile" autocomplete="tel" regex={/^[\d,+]+$/} value={phone} onChange={(e: ChangeEvent) => setPhone((e.target as HTMLInputElement).value)} required />
                        </div>
                        <InputField type="text" label="Token" value={token} onChange={(e: ChangeEvent) => setToken((e.target as HTMLInputElement).value)} required uppercase />
                        <Button text="Submit" type={ButtonType.Primary} loading={isLoading} disabled={isDisabled || formIsInvalid()} />
                      </form>

                      {errorMessage &&
                        <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
                          <p>{errorMessage}<br />Please try again.</p>
                        </Alert>
                      }
                    </>
                    :
                    <div style={{ marginTop: '0px', paddingTop: '15px' }}>
                      <Alert type={AlertType.Positive}>
                        <p>We've received your prescription details. A pharmacist will be in contact within the next 24 hours.</p>
                      </Alert>
                    </div>
                  }
                </div>
              </div>

              <StoreDetails />
            </ContentBlock>
          </StyledHelpPageNew>
        </>
      }
    </>
  );
}

const StyledHelpPageNew = styled.div`
  #help {
    .HelpPageNew_heading {
      img {
        width: 22px;
        height: auto;
        margin-bottom: -5px;
      }

      .heading {
        font-size: 0.875rem;
      }
      
      p {
        font-size: 0.775rem;
      }
    }


    .HelpPageNew_form {
      padding-top: 15px;
      margin-top: 30px;
      border-top: 1px solid ${props => props.theme.colours.grey};

      p {
        display: flex;
        align-items: center;
        /* font-size: 0.88rem; // 16px */

        &.marginTop {
          margin-top: 40px;
        }

        span {
          display: inline-flex;
          width: 1.4375rem; // 23px
          height: 1.4375rem; // 23px
          min-width: 1.4375rem; // 23px
          min-height: 1.4375rem; // 23px
          justify-content: center;
          align-items: center;
          border: 1px solid black;
          border-radius: 100%;;
          font-size: 0.75rem; // 12px
          padding-bottom: 1px;
          margin-right: 11px;
        }

        img {
          margin-left: 2px;
          margin-right: 10px;
        }
      }

      .Button {
        margin-top: 30px;
      }
    }

    .StoreDetails {
      margin-top: 30px;
    }
  }
`;
