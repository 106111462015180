import { FunctionComponent, ChangeEventHandler, FocusEventHandler, ChangeEvent } from 'react';
import { HTMLInputTypeAttribute } from "react";
import styled from 'styled-components';


type InputFieldProps = {
  type?: HTMLInputTypeAttribute
  id?: string
  label?: string
  labelAfter?: string
  placeholder?: string
  autocomplete?: string
  name?: string
  min?: number
  max?: number
  step?: string
  value?: any
  regex?: RegExp
  disabled?: boolean
  required?: boolean
  large?: boolean
  uppercase?: boolean
  onChange?: ChangeEventHandler
  onFocus?: FocusEventHandler
  onBlur?: FocusEventHandler
}

export const InputField: FunctionComponent<InputFieldProps> = ({ type, id, label, labelAfter, placeholder, autocomplete, name, min, max, step, value, regex, disabled, required, large, uppercase, onChange, onFocus, onBlur }) => {
  const validateInput = (event: ChangeEvent): void => {
    let input = (event.target as HTMLInputElement).value;
    if (uppercase) {
      (event.target as HTMLInputElement).value = input.toUpperCase();
      input = input.toUpperCase();
    }
    let isValid = true;
    
    if (regex) {
      if (input !== "" && !regex.test(input)) {
        isValid = false;
      }
    }

    if (max) {
      if (parseFloat(input) > max) (event.target as HTMLInputElement).value = max.toString();
    }
    
    if (min) {
      if (parseFloat(input) > min) (event.target as HTMLInputElement).value = min.toString();
    }
    
    if (isValid && onChange) onChange(event);
  }
  
  return (
    <StyledInputField className={`InputField ${required && 'required'} ${disabled && 'disabled'} ${large && 'large'}`}>
      <label className="InputField_label bold" htmlFor={id}>{label}</label>
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        autoComplete={autocomplete}
        name={name}
        min={min}
        max={max}
        step={step}
        value={value === undefined ? '' : value}
        onChange={validateInput}
        onFocus={onFocus}
        onBlur={onBlur}
        inputMode={type === 'number' ? 'decimal' : 'text'} 
      />
      {labelAfter &&
        <label className="InputField_labelAfter">{labelAfter}</label>
      }
    </StyledInputField>
  );
}

const StyledInputField = styled.div`
  position: relative;
  margin: 10px 0;

  input {
    width: 100%;
    height: 45px;
    padding: 13px;
    border-radius: 5px;
    border: 1px solid ${props => props.theme.colours.grey};
    margin-top: 6px;

    transition:
      border-color ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};

    &:active, &:focus-visible {
      /* border: 2px solid black; */
      border: 2px solid ${props => props.theme.colours.primary};
      outline: none;
      padding: 12px;
    }

    &:invalid {
      border: 2px solid red;
      padding: 12px;
    }

    &::placeholder {
      color: ${props => props.theme.colours.grey}
    }
  }

  &.large {
    input {
      height: 58px;
      /* margin-bottom: 14px; */
      /* border-radius: 12px; */
      font-size: 0.875rem; // 14px
    }
    label {
      font-size: 0.875rem; // 14px
    }
  }

  .InputField_labelAfter {
    position: absolute;
    bottom: 0;
    left: calc(100% + 10px);

    height: 48px;
    margin: 8px 0;
    padding: 1rem 0;
  }

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${props => props.theme.colours.primary};
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }
`
