import { FunctionComponent } from "react";
import styled from 'styled-components';

type ProgressBarProps = {
  current: number
  length: number
}

export const ProgressBar: FunctionComponent<ProgressBarProps> = ({ current, length }) => {
  const innerWidth = (): string => {
    return `${(current / length) * 100}%`;
  }

  return (
    <StyledProgressBar className="ProgressBar">
      <div className="ProgressBar_outer">
        <div className="ProgressBar_inner" style={{ width: innerWidth() }}></div>
      </div>
    </StyledProgressBar>
  );
}

const StyledProgressBar = styled.div`
  position: relative;
  width: 100%;
  
  .ProgressBar_outer {
    position: absolute;
    background: ${props => props.theme.components.progressBar.background};
    width: 100%;
    height: 15px;
    top: -6px;
    left: 0;

    .ProgressBar_inner {
      background: ${props => props.theme.components.progressBar.fill};
      height: 100%;
      border-radius: 0 2px 2px 0;
      /* transition: width 0.3s ease; */
      transition: width ${props => `${props.theme.transitions.duration.default} ${props.theme.transitions.easing.inOutCubic}`};
    }
  }
`;
