import { FunctionComponent } from "react";
import styled from 'styled-components';

type ContentBlockProps = {
  heading?: string
  children: any
  id?: string
}

export const ContentBlock: FunctionComponent<ContentBlockProps> = ({ heading, children, id }) => {
  return (
    <StyledContentBlock id={id}>
      {heading && <h3>{heading}</h3>}
      {children}
    </StyledContentBlock>
  );
}

const StyledContentBlock = styled.div`
  width: 100%;
  background: ${props => props.theme.colours.white};
  border-radius: 5px;
  margin: 20px 0;
  padding: 30px;
  /* padding: 19px 16px; */
  box-shadow: 0 0 12px rgba(115, 115, 115, 0.1);

  @media screen and (max-width: 600px) {
    padding: 19px 16px;
  }

  h3 {
    margin-top: 0;
  }
`;
