import { FunctionComponent } from "react";
import styled from 'styled-components';

type RadioGroupProps = {
  children: any
  value?: any
  id?: string
  onChange?: Function
  required?: boolean
  disabled?: boolean
}

export const RadioGroup: FunctionComponent<RadioGroupProps> = ({ children, value, id, onChange, required, disabled }) => {
  const handleClick = (): void => {
    if (onChange) onChange(value);
  }

  return (
    <StyledRadioGroup className={`RadioGroup ${required && 'required'} ${disabled && 'disabled'}`} onClick={handleClick}>
      {children}
    </StyledRadioGroup>
  );
}



const StyledRadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin: 11px 0 0 0;

  * {
    font-size: 0.625rem; // 10px
  }

  .RadioButton {
    cursor: pointer;

    &:hover {
      input {
        transform: scale(1.2);
      }
    }
  }

  input {
    appearance: none;
    background: white;
    border-radius: 100%;
    border: 3px solid transparent;
    outline: none;
    box-shadow: 0 0 0 2px ${props => props.theme.colours.grey};

    width: 18px;
    height: 18px;
    margin: 1px;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 11px;

    transition:
      border ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      box-shadow ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      padding: 1px;
      border-radius: 100%;
      background-color: ${props => props.theme.colours.primary};
      transform: scale(0);

      transition: transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
    }
  }

  .selected {
    outline: none;

    input {
      border: 3px solid white;
      outline: none;
      box-shadow: 0 0 0 2px ${props => props.theme.colours.primary};

      &:after {
        transform: scale(1);
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-size: 0.625rem; // 10px
  }

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${props => props.theme.components.progressBar.background};
    }
  }
`;
