import { ChangeEventHandler, FunctionComponent } from "react";
import styled from 'styled-components';

const tickIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/TickWhite.svg`;

type CheckboxProps = {
  children: any
  selected: boolean
  value?: any
  id: string
  onChange?: Function
  required?: boolean
  disabled?: boolean
}

export const Checkbox: FunctionComponent<CheckboxProps> = ({ children, selected, value, id, onChange, required, disabled }) => {
  const handleClick = (): void => {
    if (onChange) onChange(value);
  }

  return (
    <StyledCheckbox className={`Checkbox ${selected && 'selected'} ${required && 'required'} ${disabled && 'disabled'}`} onClick={handleClick}>
      <input type="checkbox" id={id} name={id} value={value} checked={selected} onChange={onChange as ChangeEventHandler} style={{ backgroundImage: `url(${tickIcon})` }} />
      <label className="bold" htmlFor={id}>
        {children}
      </label>
    </StyledCheckbox>
  );
}



const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  cursor: pointer;

  &:hover {
    input {
      transform: scale(1.2) !important;
    }
  }

  input {
    appearance: none;
    border: 1px solid ${props => props.theme.colours.grey} !important;
    background: ${props => props.theme.colours.white};
    border-radius: 4px;
    transform: none !important;

    width: 18px;
    height: 18px;
    margin: 1px;
    pointer-events: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px;
    margin-right: 11px;

    transition:
      border ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      background-color ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`},
      outline ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};

    &:before {
      display: none !important;
    }
  }

  &.selected {
    outline: none;

    input {
      border: 1px solid ${props => props.theme.colours.primary} !important;
      background-color: ${props => props.theme.colours.primary};
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  label {
    font-size: 0.625rem; // 10px
    letter-spacing: -0.02em;
  }

  &.required {
    label:after {
      content: "*";
      margin-left: 2px;
      color: ${props => props.theme.colours.primary};;
    }
  }
`;
