import { FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';
import { useNavigate, Navigate } from "react-router-dom";
import { ITheme, ThemeKey, getTheme } from "../assets/css/variables";

import { deliveryMethodImages } from "./DeliveryPage";

import { useAppDispatch, useAppSelector } from "../store/hooks";

import { Button, ButtonType } from "../components/Button";

const confirmationHeader = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/header-confirmation.png`;
const confirmationGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/graphic-pharmacist.svg`;
const pricelineGraphic = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/graphic-pharmacist-priceline.svg`;

type ConfirmationPageProps = {
}

export const ConfirmationPage: FunctionComponent<ConfirmationPageProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // store
  const config = useAppSelector((state) => state.script.config);
  const order = useAppSelector((state) => state.script.order);
  const delivery = useAppSelector((state) => state.script.delivery);

  const theme: ITheme = getTheme(config.theme_key);

  // state
  const [progressState, setProgressState] = useState(['incomplete', 'incomplete', 'incomplete', 'incomplete'])
  const [statusMessage, setStatusMessage] = useState('')
  const [hideStatus, setHideStatus] = useState(true)
  const [showCourier, setShowCourier] = useState(false)
  const [showTracking, setShowTracking] = useState(false)

  // methods
  const handleNavigateHome = (): void => {
    navigate('/');
  }

  // computed  
  const timerDelay = 0;
  const timerInterval = 2200;

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 10);

    // Production animation

    setTimeout(() => {
      setStatusMessage('Your order has been received!')
      setHideStatus(false);
      setProgressState(['complete', 'incomplete', 'incomplete', 'incomplete'])

      setTimeout(() => {
        setShowCourier(true)
      }, timerInterval * 0.25);
      
      // setTimeout(() => {
      //   setShowTracking(true)
      // }, timerInterval * 0.5);
    }, timerDelay + (timerInterval * 0));

    // Full demo animation of all states

    // setTimeout(() => {
    //   setStatusMessage('Your order has been received!')
    //   setHideStatus(false);
    //   setProgressState(['complete', 'incomplete', 'incomplete', 'incomplete'])

    //   setTimeout(() => {
    //     setHideStatus(true);
    //   }, timerInterval * 0.75);
    // }, timerDelay + (timerInterval * 0));
    
    // setTimeout(() => {
    //   setStatusMessage('Your order is being packed...')
    //   setHideStatus(false);
    //   setProgressState(['complete', 'progress', 'incomplete', 'incomplete'])

    //   setTimeout(() => {
    //     setHideStatus(true);
    //   }, timerInterval * 0.75);
    // }, timerDelay + (timerInterval * 1));
    
    // setTimeout(() => {
    //   setStatusMessage('Awaiting delivery driver...');
    //   setHideStatus(false);
    //   setProgressState(['complete', 'complete', 'progress', 'incomplete'])

    //   setTimeout(() => {
    //     setHideStatus(true);
    //   }, timerInterval * 0.75);
    // }, timerDelay + (timerInterval * 2));
    
    // setTimeout(() => {
    //   setStatusMessage('Your order is on its way...');
    //   setHideStatus(false);
    //   setProgressState(['complete', 'complete', 'complete', 'progress'])

    //   setTimeout(() => {
    //     setShowCourier(true)
    //   }, timerInterval * 0.25);
      
    //   setTimeout(() => {
    //     setShowTracking(true)
    //   }, timerInterval * 0.5);

    //   setTimeout(() => {
    //     setHideStatus(true);
    //   }, timerInterval * 1.75);
    // }, timerDelay + (timerInterval * 3));
    
    // setTimeout(() => {
    //   setStatusMessage('Your order has been delivered!');
    //   setHideStatus(false);
    //   setProgressState(['complete', 'complete', 'complete', 'complete'])
    // }, timerDelay + (timerInterval * 5));
  }, []);

  return (
    <>
      {!config.has_landed ?
        <Navigate to="/" />
      :
        <StyledConfirmationPage>
          {config.theme_key === ThemeKey.Priceline ?
            <img src={confirmationHeader} alt="Your order is with us" className="Confirmation_headerImage" />
          :
            <h1 className="Confirmation_header">Your order is with us!</h1>
          }

          <div className="Confirmation_order">
            <p className="bold">Order</p>
            <p className="light">{order.order_number}</p>
          </div>

          <img src={config.theme_key === ThemeKey.Priceline ? pricelineGraphic : confirmationGraphic} alt="" className="Confirmation_graphic" />

          <div className="Confirmation_progress">
            <div className="Confirmation_progressBar">
              {progressState.map((state: any, i: number) => {
                return (
                  <div key={i} className={state}></div>
                )
              })}
            </div>
            <p className={`bold elementTransition ${(statusMessage === '' || hideStatus) && 'hidden'}`}>{statusMessage}</p>
          </div>

          <div className={`Confirmation_courier elementTransition ${!showCourier && 'hidden'}`}>
            <p className="light">{order.delivery_method === 'pickup' ? 'Pick up your order from:' : 'Your order will be delivered by:'}</p>
            <img style={{ marginTop: '5px' }} src={order.delivery_method === 'pickup' ? theme.images.logo : deliveryMethodImages[order.delivery_method as keyof typeof deliveryMethodImages]} alt={order.delivery_method_name} />
          </div>

          <div className={`Confirmation_button elementTransition ${!showTracking && 'hidden'}`}>
            <Button text="Track your Delivery" type={ButtonType.Primary} />
          </div>
          <Button text="Back to Home" type={ButtonType.Secondary} onClick={handleNavigateHome} />
        </StyledConfirmationPage>
      }
    </>
  );
}

const StyledConfirmationPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Confirmation_headerImage {
    width: 100%;
    max-width: 400px;
    margin: 50px auto 0 auto;
  }

  .Confirmation_header {
    margin: 50px 0 30px 0;
  }

  .Confirmation_order {
    p:nth-child(1) {
      color: ${props => props.theme.colours.primary};
      margin: 0;
    }
    p:nth-child(2) {
      font-size: 1.375rem; // 22px
      margin-top: 0;
    }
  }

  .Confirmation_graphic {
    height: 100px;
    width: auto;
    margin-right: auto;
  }

  .Confirmation_progress {
    margin-top: 18px;
    height: 52px;

    .Confirmation_progressBar {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      div {
        background-color: ${props => props.theme.components.confirmation.incomplete};
        width: 100%;
        height: 9px;
        border-radius: 2px;

        transition: background-color ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
        animation-iteration-count: 1;

        &.complete {
          background-color: ${props => props.theme.components.confirmation.complete};
          animation: pop ${props => `${props.theme.transitions.duration.slow} ${props.theme.transitions.easing.inOutCubic}`};
        }
        
        &.progress {
          background-color: ${props => props.theme.components.confirmation.progress};
          animation: pop ${props => `${props.theme.transitions.duration.slow} ${props.theme.transitions.easing.inOutCubic}`};
        }
      }
    }

    p {
      font-size: 1rem; // 16px
      margin: 12px 0 12px 0;
    }
  }

  .Confirmation_courier {
    margin-bottom: 40px;

    p {
      font-size: 1rem; // 16px
      margin-bottom: 5px;
      height: 19px;
    }
    img {
      width: 120px;
    }
  }

  .Confirmation_button {
    height: 53px;
    margin-bottom: 14px;
  }

  .Button_primary {
    margin-bottom: 0;
  }
`;
