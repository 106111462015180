import { FunctionComponent } from "react";
import styled from 'styled-components';
import { ITheme, getTheme } from '../assets/css/variables';

import { useAppSelector } from "../store/hooks";
import { formatPhoneString } from "../store/scriptSlice";

type StoreDetailsProps = {
}

export const StoreDetails: FunctionComponent<StoreDetailsProps> = ({ }) => {
  const config = useAppSelector((state) => state.script.config);
  const theme: ITheme = getTheme(config.theme_key);

  return (
    <StyledStoreDetails className="StoreDetails">
      <div className="StoreDetails_details">
        <p className="heading bold">Give us a call or visit us in store</p>

        <div className="StoreDetails_detail">
          <span className="bold">Address:</span>
          <span>{config.location_address}</span>
        </div>
        
        <div className="StoreDetails_detail">
          <span className="bold">Phone:</span>
          <span>{formatPhoneString(config.location_phone || '')}</span>
        </div>
      </div>
      
      {/* <div className="StoreDetails_maps">
        TODO IFRAME
      </div> */}
    </StyledStoreDetails>
  );
}

const StyledStoreDetails = styled.div`
  padding: 10px 8px 10px 18px;
  background: ${props => props.theme.colours.background};
  border-radius: 6px;
  display: flex;
  align-items: center;

  .StoreDetails_details {
    flex-grow: 1;

    .heading {
      font-size: 0.78rem; // 14px
      margin: 6px 0 11px 0;
    }

    .StoreDetails_detail {
      font-size: 0.66rem; // 12px
      display: flex;
      margin: 6px 0;

      span:first-child {
        display: inline-block;
        min-width: 60px;
      }
    }
  }
  
  .StoreDetails_maps {
    background: grey;
    width: 160px;
    height: 80px;
    margin-left: 20px;
  }
`;
