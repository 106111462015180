import { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';

import { ITheme, getTheme } from '../assets/css/variables';

import scriptSlice, { Prescription, PrescriptionPayload, setScript } from '../store/scriptSlice'
import { useAppDispatch, useAppSelector } from "../store/hooks";

import { ContentBlock } from "./ContentBlock";
import { InputField } from "./InputField";
import { Checkbox } from "./Checkbox";
import { SelectInput, SelectInputOption } from "./SelectInput";
import { Alert, AlertType } from "./Alert";

const trashIcon = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/icon-trash.svg`;
const escriptHelp = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/escript-help-wide-generic.gif`;
const erxLogo = `${process.env.REACT_APP_ASSET_BASE_PATH}/images/logo-erx.png`;

const entitlementOptions: Array<SelectInputOption> = [
  {
    value: 'private',
    label: 'No'
  },
  {
    value: 'pbs',
    label: 'I have a Medicare card'
  },
  {
    value: 'concession',
    label: 'I have a concession or health care card'
  }
]

export enum PatientInputType {
  Form = 'form',
  Toggle = 'toggle',
  Failover = 'failover'
}

type ScriptBlockProps = {
  script: Prescription
  scriptIndex: number
  patientInputType?: PatientInputType
  onRemove?: Function
  medicationOptions?: Array<SelectInputOption> | null
  selectedMedication?: any
  onSelectMedication?: Function
  onChangeProduct?: Function
}

export const ScriptBlock: FunctionComponent<ScriptBlockProps> = ({ script, scriptIndex, patientInputType, onRemove, medicationOptions, selectedMedication, onSelectMedication, onChangeProduct }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const config = useAppSelector((state) => state.script.config);
  const scripts = useAppSelector((state) => state.script.scripts);
  const theme: ITheme = getTheme(config.theme_key);
  
  // state
  // const [entitlement, setEntitlement] = useState('medicare');
  // const [firstName, setFirstName] = useState('Hamish');
  // const [lastName, setLastName] = useState('Henderson');
  // const [email, setEmail] = useState('hamish@rivalsoftware.com.au');
  // const [phone, setPhone] = useState('0421148166');
  const [sameDetails, setSameDetails] = useState(true);
  const [sameDetailsEntitlement, setSameDetailsEntitlement] = useState(true);
  
  // methods
  const handleRemove = () => {
    if (onRemove) onRemove();
  }
  
  const handleGetHelp = () => {
    navigate('/help');
  }

  const handleChangeProduct = () => {
    if (script.products.length === 1) {
      navigate('/help');
    } else {
      if (onChangeProduct) onChangeProduct();
    }
  }

  // computed
  const crnInputLabel = (): string => {
    switch (script.entitlement) {
      case 'private':
        return 'Customer Reference Number (Not Applicable)';
      case 'pbs':
        return 'Medicare Number';
      case 'concession':
        return 'Customer Reference Number (CRN)';
      case 'safety_net':
        return 'Customer Reference Number (CRN)';
      default:
        return 'Customer Reference Number';
    }
  }
  
  const crnInputPlaceholder = (): string => {
    switch (script.entitlement) {
      case 'pbs':
        return '1234 56789 1';
      case 'concession':
      case 'safety_net':
        return '123-456-789A';
      default:
        return '';
    }
  }

  const formattedDosage = () => {
    return script.dosage.map((object: any) => object.text).sort((a: any, b: any) => a.sequence > b.sequence ? 1 : -1).join('. ');
  }

  const formattedEntitlement = (script: Prescription): string => {
    switch (script.entitlement) {
      case 'private':
        return 'Private';
      case 'pbs':
        return `Medicare (${script.entitlement_number})`
      case 'concession':
        return `Concession (${script.entitlement_number})`
      case 'safety_net':
        return `Safety Net (${script.entitlement_number})`
      default:
        return script.entitlement.charAt(0).toUpperCase() + script.entitlement.slice(1)
    }
  }

  useEffect(() => {
    if (!['concession', 'pbs', 'safety_net'].includes(script.entitlement)) {
      dispatch(setScript({ index: scriptIndex, entitlement_number: '' }));
    }
  }, [ script.entitlement ])

  useEffect(() => {
    const payload: PrescriptionPayload = { index: scriptIndex }

    if (sameDetails) {
      payload.customer_firstname = scripts[0].customer_firstname;
      payload.customer_lastname = scripts[0].customer_lastname;
      payload.customer_email = scripts[0].customer_email;
      payload.delivery_phone = scripts[0].delivery_phone;
    } else {
      payload.customer_firstname = '';
      payload.customer_lastname = '';
      payload.customer_email = '';
      payload.delivery_phone = '';
    }

    if (sameDetailsEntitlement) {
      payload.entitlement = scripts[0].entitlement;
      payload.entitlement_number = scripts[0].entitlement_number;
    } else {
      payload.entitlement = 'private';
      payload.entitlement_number = '';
    }

    dispatch(setScript(payload));
  }, [ sameDetails, sameDetailsEntitlement ])

  return (
    <ContentBlock>
      <StyledScriptBlock className={`ScriptBlock ScriptBlock_${process.env.REACT_APP_TARGET}`}>
        {!patientInputType &&
          <a
            className="ScriptBlock_viewEscript link bold"
            href={`https://ausscripts.erx.com.au/Scripts/${script.token}`}
            target="_blank"
            rel="noreferrer"
          >
            View e-script
          </a>
        }

        {onRemove &&
          <img src={trashIcon} alt="Remove script" className="ScriptBlock_remove" onClick={handleRemove}/>
        }
        
        {!patientInputType &&
          <>
            <div className="ScriptBlock_header ScriptBlock_headerNoInput">
              <div className="ScriptBlock_qrcode">
                <QRCodeSVG value={script.token} size={200} level="L" />
              </div>
              <div>
                <h3>{script.name}</h3>
                <p><span className="bold link" onClick={handleChangeProduct}>Not the right product?</span></p>
                <p><span className="bold">Token:</span> {script.token}</p>
              </div>
            </div>
            <div className="ScriptBlock_details">
              <p><span className="bold">Name:</span>{(script.script_firstname && script.script_lastname) ? `${script.script_firstname} ${script.script_lastname}` : `${script.customer_firstname} ${script.customer_lastname}`}</p>
              {/* <p><span className="bold">Entitlement:</span>{formattedEntitlement(script)}</p> */}
              <p><span className="bold">Dosage:</span>{formattedDosage()}</p>
            </div>

            <div className="ScriptBlock_entitlement">
              <SelectInput 
                id="entitlement" 
                label="Does the patient have a Medicare, concession or health care card?" 
                options={entitlementOptions} 
                value={script.entitlement} 
                // onChange={(e: ChangeEvent) => setEntitlement((e.target as HTMLInputElement).value)} 
                onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, entitlement: (e.target as HTMLInputElement).value}))}
                required
              />
              {['concession', 'pbs', 'safety_net'].includes(script.entitlement) &&
                <InputField type="text" label={crnInputLabel()} placeholder={crnInputPlaceholder()} value={script.entitlement_number} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, entitlement_number: (e.target as HTMLInputElement).value}))} required />
              }
            </div>
          </>
        }
        
        {(patientInputType && patientInputType !== PatientInputType.Failover) &&
          <>
            {/* <div className="ScriptBlock_header">
              <div className="ScriptBlock_token">
                <h3>Token:</h3>
                <p>{script.token}</p>
              </div>
            </div> */}

            <form className="ScriptBlock_patientInformation" name="Enter patient details" id="form_script-block_patient-details">
              <h3>E-Script Token</h3>
              <InputField type="text" label="Token" value={script.token} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, token: (e.target as HTMLInputElement).value}))} required uppercase />

              {scripts.length !== 1 &&
                <>
                  <h3>Entitlement</h3>
                  {patientInputType === PatientInputType.Toggle &&
                    <Checkbox id="entitlement" selected={sameDetailsEntitlement} onChange={() => setSameDetailsEntitlement(val => !val)}>
                      <>Use the same entitlement information as first prescription</>
                    </Checkbox>
                  }
                  {(patientInputType === PatientInputType.Form || !sameDetailsEntitlement) &&
                    <>
                      <SelectInput 
                        id="entitlement" 
                        label="Do you have a Medicare, concession or health care card?" 
                        options={entitlementOptions} 
                        value={script.entitlement} 
                        // onChange={(e: ChangeEvent) => setEntitlement((e.target as HTMLInputElement).value)} 
                        onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, entitlement: (e.target as HTMLInputElement).value}))}
                        required
                      />
                      {['concession', 'pbs', 'safety_net'].includes(script.entitlement) &&
                        <InputField type="text" label={crnInputLabel()} placeholder={crnInputPlaceholder()} value={script.entitlement_number} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, entitlement_number: (e.target as HTMLInputElement).value}))} required />
                      }
                    </>
                  }
                </>
              }

              <h3>Patient Information</h3>
              {patientInputType === PatientInputType.Toggle &&
                <Checkbox id="terms" selected={sameDetails} onChange={() => setSameDetails(val => !val)}>
                  <>Use the same patient information as first prescription</>
                </Checkbox>
              }
              {(patientInputType === PatientInputType.Form || !sameDetails) &&
                <div className="inputColumns">
                  <InputField type="text" label="First name" name="fname" autocomplete="given-name" value={script.customer_firstname} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, customer_firstname: (e.target as HTMLInputElement).value}))} required />
                  <InputField type="text" label="Last name" name="lname" autocomplete="family-name" value={script.customer_lastname} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, customer_lastname: (e.target as HTMLInputElement).value}))} required />
                  <InputField type="email" label="Email" name="email" autocomplete="email" value={script.customer_email} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, customer_email: (e.target as HTMLInputElement).value}))} required />
                  <InputField type="tel" label="Mobile number" name="mobile" autocomplete="tel" regex={/^[\d,+]+$/} value={script.delivery_phone} onChange={(e: ChangeEvent) => dispatch(setScript({ index: scriptIndex, delivery_phone: (e.target as HTMLInputElement).value}))} required />
                </div>
              }
            </form>
          </>
        }

        {(patientInputType && patientInputType === PatientInputType.Failover) &&
          <>
            <div className="ScriptBlock_header">
              <img className="ScriptBlock_erxLogo" src={erxLogo} alt="eRx script exchange" />
              <div className="ScriptBlock_token">
                <h3>Token:</h3>
                <p>{script.token}</p>
              </div>
            </div>
            <div className="ScriptBlock_failoverInput">
              <h3>Please confirm the prescribed medication from the list below</h3>
              <p>
                A pharmacist will confirm the medication matches your prescription before dispensing.
                {/* If you need assistance or cannot find your medication, please give us a call on&nbsp;
                <a className="link bold" href={`tel:${theme.data.phone}`}>{theme.data.phoneDisplay || theme.data.phone}</a>. */}
              </p>
              <form name="Please confirm the prescribed medication from the list below" id="form_script-block_failover">
                <SelectInput 
                  id="scriptConfirmation" 
                  options={medicationOptions!} 
                  value={selectedMedication} 
                  onChange={(e: ChangeEvent) => onSelectMedication!((e.target as HTMLInputElement).value)} 
                  required
                />
              </form>
            </div>
            <div className="ScriptBlock_failoverHelp">
              <p className="italic">Where can I find the medication name?</p>
              <img src={escriptHelp} alt="" />
              {/* <Alert type={AlertType.Notes}>
                <p className="semibold">The pharmacist will confirm the medication matches your prescription at the point of dispensing.</p>
              </Alert> */}
              <div className="getHelp">
                <p className="bold">Can't find the right medication in the list above?</p>
                <button className="bold button" onClick={handleGetHelp}>Get Help</button>
              </div>
            </div>
          </>
        }
      </StyledScriptBlock>
    </ContentBlock>
  );
}

const StyledScriptBlock = styled.div`
  position: relative;
  margin-bottom: -8px;

  h3 {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }
  
  .ScriptBlock_viewEscript {
    font-size: 0.75rem; // 12px
    position: absolute;
    margin: 0;
  }

  .ScriptBlock_remove {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px;
    filter: ${props => props.theme.colours.filterPrimary};
    cursor: pointer;

    /* animation */
    transition: transform ${props => `${props.theme.transitions.duration.fast} ${props.theme.transitions.easing.inOutCubic}`};
    
    &:hover {
      transform: scale(1.2);
    }

    &:active {
      transform: scale(0.9);
    }
  }
  
  .ScriptBlock_header,
  .ScriptBlock_details {
    p {
      font-size: 0.625rem; // 10px
      margin-top: 0;
      margin-bottom: 8px;
    }
  }

  .ScriptBlock_header {
    display: flex;
    gap: 23px;
    align-items: center;
    margin-bottom: 10px;

    .ScriptBlock_erxLogo {
      width: 82px;
      height: auto;
    }

    .ScriptBlock_qrcode {
      width: 45px;
      height: auto;

      svg {
        width: 100%;
        height: auto;
      }
    }

    h3 {
      margin: 0 0 5px 0;
    }

    .ScriptBlock_token {
      width: 100%;

      p {
        font-size: 0.875rem; // 14px
      }
    }
  }

  .ScriptBlock_details {
    /* columns: 2; */

    p {
      span {
        display: inline-block;
        width: 68px;
      }
    } 
    /* 2nd column */
    /* p:nth-child(n+3) {
      span {
        display: inline-block;
        width: 110px;
      }
    }  */
  }

  .ScriptBlock_patientInformation {
    /* border-top: 1px solid ${props => props.theme.colours.grey}; */
    /* padding-top: 16px;
    margin-top: 10px; */
  }
  
  .ScriptBlock_failoverInput {
    border-top: 1px solid ${props => props.theme.colours.grey};
    padding-top: 16px;
    margin-top: 10px;

    h3 {
      margin-bottom: 0;
    }
    
    p {
      margin-top: 5px;
      font-size: 0.625rem; // 10px
    }
  }

  .ScriptBlock_failoverHelp {
    .Alert {
      margin: 15px 0;

      p {
        line-height: 1.65;
      }
    }

    img {
      max-width: 100%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin-top: 5px;
    }

    .getHelp {
      padding: 10px;
      background: ${props => props.theme.colours.background};
      text-align: center;
      margin: 20px 0;
      border-radius: 6px;

      button {
        /* width: 84px; */
        color: ${props => props.theme.colours.secondary};
        border: 1px solid ${props => props.theme.colours.secondary};
        background: none;
        border-radius: 6px;
        padding: 6px 15px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
      }
    }
  }

  /* DESKTOP or WIDGET */

  &.ScriptBlock_desktop, &.ScriptBlock_widget {
    .ScriptBlock_headerNoInput {
      flex-direction: column;
      gap: 15px;
      align-items: center;
      margin-bottom: 20px;
      padding: 15px 0;
      border-bottom: 1px solid ${props => props.theme.colours.grey};
      text-align: center;

      .ScriptBlock_qrcode {
        width: 100px;
      }

      h3 {
        margin: 0 0 8px 0;
        font-size: 1rem; // 16px
      }
      
      p {
        font-size: 0.75rem; // 12px
      }
    }

    .ScriptBlock_details {
      p {
        text-align: center;
        font-size: 0.75rem; // 12px

        .bold {
          width: fit-content;
          margin-right: 5px;
        }
      }
    }
  }

  .ScriptBlock_entitlement {
    border-top: 1px solid ${props => props.theme.colours.grey};
    margin-top: 20px;
    padding-top: 10px;
  }
`;
